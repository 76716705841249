@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
	.footer-layout {
		grid-template-areas:
			'a'
			'c'
			'b';
	}

	.footer-layout-lg {
		grid-template-areas:
			'a a a c c c'
			'b b b c c c';
	}

	.grid-area-a {
		grid-area: a;
	}

	.grid-area-b {
		grid-area: b;
	}

	.grid-area-c {
		grid-area: c;
	}
}

@layer base {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-serif;
		@apply font-bold;
	}

	body {
		@apply font-sans;
	}

	.prose,
	.prose-sm,
	.prose-lg,
	.prose-xl {
		a {
			@apply underline;
		}

		ul {
			@apply list-disc;
		}
	}
}
